<template>
    <div class="container">
        <div>
            <arquivodigital-search/>
            <arquivodigital-importar/>
            <arquivodigital-create/>
            <arquivodigital-update/>
            <arquivodigital-delete/>
            <arquivodigital-detalhes/>
            <arquivodigital-importacoes/>
            <arquivodigital-importacoes-delete/>
            <arquivodigital-anexos-modal/>
            <arquivodigital-add-anexo-modal/>
            <arquivodigital-remove-anexo-modal/>
            <arquivodigital-update-data-anexo-modal/>
        </div>
        <div class="row remove-padding-left-right-grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card card-border-top">
                    <div class="card-body">
                        <div>
                            <h4 class="card-title">Arquivos Digitalizados -
                                <span style="font-size: 14px; color: red;">
                                    {{ progresso_upload_competencia.arquivos_com_anexos }} / {{ progresso_upload_competencia.total_arquivos_digitais }} 
                                    {{ getPercentualProgressoUploadCompetencia() }}
                                </span> 
                            </h4>
                        </div>
                        <p class="card-description text-right">
                            <input
                                style="display: inline-block; width: 20%;" 
                                type="text" 
                                class="form-control" 
                                placeholder="Buscar por Nº Empenho"
                                v-model="empenho_numero_search"
                                @keyup.enter="searchEmpenhoNumero"
                            />
                            <button @click="initImportacoes()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-format-list-bulleted"></i>Importações
                            </button>
                            <button @click="initImportar()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Importar
                            </button>
                            <button @click="initSearch()" type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-filter"></i>
                                {{ titleButtonFilter }}
                            </button>
                            <button @click="initAdd()"  type="button" class="btn btn-outline-primary">
                                <i class="mdi mdi-plus-circle"></i>Novo
                            </button>
                        </p>
                        <div class="table-responsive" style="min-height: 60vh;">
                            <table class="table table-hover">
                                <thead>
                                    <tr class="d-flex">
                                        <th :style="columns.one"> Descrição Resumida</th>
                                        <th :style="columns.two"> 
                                            Unidade Gestora / Unid. Orçamentária / Fornecedor
                                        </th>
                                        <th :style="columns.three" class="text-center"> Nº Empenho </th>
                                        <th :style="columns.four"> 
                                            Valor
                                        </th>
                                        <th :style="columns.five" class="text-center"> 
                                            Data Emissão
                                        </th>
                                        <th :style="columns.six" class="text-center"> 
                                            Data Cadastro
                                        </th>
                                        <th :style="columns.seven" class="text-center"> Ações </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-flex" v-for="(item, index) in arquivos_digital">
                                        <td :style="columns.one" class="vertical-center-middle">
                                            {{ item.descricao_resumida }}
                                        </td>
                                        <td :style="columns.two" class="vertical-center-middle">
                                            <div>
                                                <b>{{ item.empenho_unidade_gestora_nome }}</b> /<br/>
                                                {{ item.empenho_unidade_orcamentaria_nome }} /<br/>
                                                <span style="color: #5e83d6; font-weight: bold">
                                                    {{ item.empenho_fornecedor }}
                                                </span>
                                            </div>
                                        </td>
                                        <td :style="columns.three" class="vertical-center-middle text-center"> 
                                            {{ item.empenho_numero }}
                                        </td>
                                        <td :style="columns.four" class="vertical-center-middle"> 
                                            <span v-if="item.tipo == 'empenho'">
                                                {{ item.empenho_valor | formatMoneyBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'liquidacao'">
                                                {{ item.liquidacao_valor | formatMoneyBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'pagamento'">
                                                {{ item.pagamento_valor_pago | formatMoneyBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'licitacao'">
                                                {{ item.licitacao_valor | formatMoneyBr }}
                                            </span>
                                        </td>
                                        <td :style="columns.five" class="vertical-center-middle text-center">
                                            <span v-if="item.tipo == 'empenho'">
                                                {{ item.empenho_data_emissao | formatDataBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'liquidacao'">
                                                {{ item.liquidacao_data_emissao | formatDataBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'pagamento'">
                                                {{ item.pagamento_data_movimentacao | formatDataBr }}
                                            </span>
                                            <span v-else-if="item.tipo == 'licitacao'">
                                                {{ item.licitacao_data | formatDataBr }}
                                            </span>
                                        </td>
                                        <td :style="columns.six" class="vertical-center-middle text-center"> 
                                            {{ item.created_at | formatDataBr }}
                                        </td>
                                        <td :style="columns.seven" class="vertical-center-middle text-center">
                                            <div class="dropdown">
                                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                                                    :class=" (item.possui_anexos) ? 'btn btn-success' : 'btn btn-outline-primary' "
                                                >
                                                    <i class="mdi mdi-format-list-bulleted"></i>
                                                </button>
                                                
                                                <div class="dropdown-menu" 
                                                    x-placement="top-start" >
                                                    <a @click="initUpdate(item)" class="dropdown-item">
                                                        Editar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDelete(item)" class="dropdown-item">
                                                        Deletar
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initAnexos(item)" class="dropdown-item">
                                                        Anexos
                                                    </a>
                                                    <div class="dropdown-divider"></div>
                                                    <a @click="initDetalhes(item)" class="dropdown-item">
                                                        Detalhes
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="arquivos_digital.length == 0">
                                        <td colspan="9" class="text-center">
                                            Nenhum item encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="mt-3">
                            <pagination :paginationData="pagination" @navigate="goToPage"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      
    </div>
</template>

<style type="text/css" scoped>
    .card-title {
        margin-bottom: 0px !important;
        font-size: 18px !important;
        padding-top: 16px !important;
        padding-left: 10px !important;
    }
    .card-description {
        margin-bottom: 8px !important;
    }
    .card-border-top {
        border-top: 3px solid #149AF9 !important;
    }
    .card-body {
        padding: 0px 10px !important;
    }
    .btn {
        padding: 7px 7px !important;
    }
    .vertical-center-middle {
        /* white-space:normal;
        text-align: justify;
        display: flex;
        align-items: center; */

        white-space: normal;        /* Permite quebra de linha */
        text-align: justify;        /* Alinha o texto de forma justificada */
        display: flex;              /* Utiliza o modelo flexbox */
        flex-direction: column;     /* Organiza os itens na vertical */
        justify-content: center;    /* Centraliza os itens verticalmente */
        /*align-items: center;*/        /* Centraliza os itens horizontalmente */
        
    }

    .text-center {
        justify-content: center
    }
    
    /*table thead tr th, table tbody tr td{
        font-size: 4px;
    }*/
    
    table tbody tr td{
        font-size: 11px;
    }
</style>

<script>

    import moment from 'moment';
    import { TheMask } from 'vue-the-mask';
    import { eventBus } from '../../app';
    import Pagination from '../paginacao/Pagination.vue';

    export default {

        components: {
            TheMask,
            Pagination
        },

        data(){
            return {
                configDateMask: {
                    maskedValue: true
                },
                titleButtonFilter: '(1) Filtro',
                columns: {
                    one: 'width: 32%; font-size: 13px;', //DESC
                    two: 'width: 28%; font-size: 13px;', //UNID. ORÇA / FORNECEDOR
                    three: 'width: 9%; font-size: 13px;', // NUM EMPENHO
                    four: 'width: 9%; font-size: 13px;', //VALOR
                    five: 'width: 8%; font-size: 13px;', //DATA EMISSAO
                    six: 'width: 8%; font-size: 13px;', //DATA CADASTRO
                    seven: 'width: 5%; font-size: 13px;' //AÇÕES
                },
                
                isLoading: false,
                
                //ARRAYS
                arquivos_digital: [],

                //SEARCH DATA
                filtros: [],

                //PAGINATION
                pagination: {},

                empenho_numero_search: null,

                competencia_url: null,

                progresso_upload_competencia: {
                    'total_arquivos_digitais': 0,
                    'arquivos_com_anexos': 0
                }
            }
        },

        mounted() {
            this.registryEvents();
            var urlSplit = window.location.pathname.split('/');
            var competencia = parseInt(urlSplit[3]);
            if(!isNaN(competencia)){
                this.competencia_url = competencia;
                this.getArquivosDigital();
            }
            this.setTitleFilterButton();
        },

        filters: {
            formatDataBr: function (date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY');
                }
                return 'Não Informado';
            },
            formatTipo: function (tipoParam) {
                var allTipos = {
                    'empenho': 'Empenho',
                    'liquidacao': 'Liquidação',
                    'pagamento': 'Pagamento',
                    'prestacao_contas': 'Prestação de Contas',
                    'licitacao': 'Licitação',
                    'setor_pessoal': 'Setor Pessoal',
                    'outros': 'Outros'
                };
                return allTipos[tipoParam];
            },
            formatMoneyBr: function (valor){
                var formatter = new Intl.NumberFormat('pt-br', {
                  style: "currency",
                  currency: "BRL"
                });
                return formatter.format(valor);
            },
        },

        methods: {
            getPercentualProgressoUploadCompetencia() {
                const { arquivos_com_anexos: comAnexo = 0, total_arquivos_digitais: total = 0 } = this.progresso_upload_competencia || {};
                if (total === 0) {
                    return "";
                }
                const percentual = (comAnexo / total) * 100;
                return ` - ${Math.floor(percentual)}%`;
            },
            searchEmpenhoNumero() {
                let propertyToCheck = "empenho_numero";
                let newValue = this.empenho_numero_search;
                if(newValue){
                    let existingObject = this.filtros.find(item => item.property === propertyToCheck);
                    if (existingObject) {
                        existingObject.value = newValue;
                    } 
                    else {
                        this.filtros.push({ property: propertyToCheck, value: newValue });
                    }
                }
                else {
                    let newFilters = this.filtros.filter(item => item.property !== propertyToCheck)
                    this.filtros = newFilters;
                }
                this.getArquivosDigital();
            },
            //REGISTRY EVENTS
            registryEvents(){
                this.registryEventGetArquivosDigital();
                this.registryEventGetArquivosDigitalFilter();
            },
            registryEventGetArquivosDigital(){
                eventBus.$on('eventGetArquivosDigital', (page) => {
                    this.getArquivosDigital(page);
                });
            },
            registryEventGetArquivosDigitalFilter(){
                eventBus.$on('eventGetArquivosDigitalFilter', (filtrosEvent) => {
                    this.filtros = filtrosEvent;
                    this.setTitleFilterButton();
                    this.getArquivosDigital();
                });
            },
            //FIM REGISTRY EVENTS

            emitEventOpenModalSearch(){
                eventBus.$emit('eventOpenModalSearchArquivoDigital', 
                    this.filtros,
                    this.competencia_url
                );
            },
            emitEventOpenModalImportacoes(){
                eventBus.$emit('eventOpenModalImportacoesArquivoDigital');
            },
            emitEventOpenModalImportar(){
                eventBus.$emit('eventOpenModalImportarArquivoDigital', this.competencia_url);
            },
            emitEventOpenModalCreate(){
                eventBus.$emit('eventOpenModalCreateArquivoDigital', this.competencia_url);
            },
            emitEventOpenModalUpdate(item){
                eventBus.$emit('eventOpenModalUpdateArquivoDigital', 
                    Object.assign({}, item),
                    this.pagination.current_page
                );
            },
            emitEventOpenModalAnexos(item){
                eventBus.$emit('eventOpenModalAnexosArquivoDigital', 
                    item,
                    this.pagination.current_page
                );
            },
            emitEventOpenModalDetalhes(item){
                eventBus.$emit('eventOpenModalDetalhesArquivoDigital', 
                    item
                );
            },
            emitEventOpenModalDelete(item){
                eventBus.$emit('eventOpenModalDeleteArquivoDigital', 
                    item,
                    this.pagination.current_page
                );
            },
            initSearch(){
                this.emitEventOpenModalSearch();
            },
            initImportacoes(){
                this.emitEventOpenModalImportacoes();
            },
            initImportar()
            {
                this.emitEventOpenModalImportar();
            },
            initAdd()
            {
                this.emitEventOpenModalCreate();
            }, 
            initUpdate(item)
            {
                this.emitEventOpenModalUpdate(item);
            },
            initDelete(item)
            {
                this.emitEventOpenModalDelete(item);
            },
            initAnexos(item)
            {
                this.emitEventOpenModalAnexos(item);
            },
            initDetalhes(item){
                if(item.tipo == 'outros'){
                    toastr.error('Todas as informações do arquivo já estão listadas.');
                    return false;
                }
                this.emitEventOpenModalDetalhes(item);
            },

            openArquivoPdf(item){
                var urlPdf = '/arquivodigital/pdf?arquivo_digital_id='+item.id;

                this.isLoading = true;

                var child = window.open(urlPdf); 
                child.focus(); 

                this.isLoading = false;
            },

            getArquivosDigital(page)
            {
                var urlArquivosDigital = '/arquivodigital/listdata';

                this.isLoading = true;
                this.arquivos_digital = [];

                //POR PADRÃO OS ARQUIVOS SÃO LISTADOS PELA COMPETENCIA INFORMADA
                if (this.filtros.length == 0) {
                    this.filtros.push({ 
                        'property': 'competencia',
                        'value': this.competencia_url
                    });
                }

                this.getProgressoUploadCompetencia(this.competencia_url);

                axios({
                    method: 'GET',
                    url: urlArquivosDigital,
                    params: {
                        filters: this.filtros,
                        page: page //UTILIZADO NA PAGINAÇÃO
                    }
                })
                .then(response => {
                    this.arquivos_digital = response.data.arquivos_digital.data;
                    this.pagination = response.data.pagination;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    toastr.error('Falha ao atualizar a pagina');
                });
            },
            getProgressoUploadCompetencia(competencia)
            {
                this.progresso_upload_competencia = {
                    'total_arquivos_digitais': 0,
                    'arquivos_com_anexos': 0
                };
                if(competencia){
                    var urlProgressoUpload = '/arquivodigital/progressouploadcompetencia/'+competencia;
                    axios({
                        method: 'GET',
                        url: urlProgressoUpload
                    })
                    .then(response => {
                        this.progresso_upload_competencia = response.data;
                    });
                }
            },
            goToPage: function (page)
            {
                this.pagination.current_page = page;
                this.getArquivosDigital(page);
            },
            setTitleFilterButton(){
                if(this.filtros.length > 0){
                    var textFilter = this.filtros.length > 1 ? 'Filtros' : 'Filtro';
                    this.titleButtonFilter = '('+this.filtros.length+') '+textFilter;
                }
                else {
                    this.titleButtonFilter = 'Filtrar'; 
                }
            },
        }
    }
</script>
